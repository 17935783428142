import { description } from '../../../package.json';

const downloadInstructions =  {
	page: {
		en: `
			<p>You can download the Desktop Version of the <b>${description}</b> here.</p>
			<p>Your serial number grants one license for installation on a single computer.</p>
		`,
		el: `
			<p>Εδώ μπορείτε να κατεβάσετε την Desktop έκδοση του <b>${description}</b>.</p>
			<p>Ο σειριακός σας αριθμός χορηγεί μία άδεια χρήσης για εγκατάσταση σε έναν υπολογιστή.</p>
		`,
		de: `
			<p>Die Download-Version deines <b>${description}</b> findest du hier.</p>
			<p>Deine Seriennummer gewährt eine Installationslizenz  auf einem einzelnen Computer.</p>
		`,
		fr: `
			<p>Vous pouvez ici télécharger votre version desktop du <b>${description}</b>.</p>
			<p>Votre numéro de série accorde une licence pour l'installation sur un seul ordinateur.</p>
		`,
		he: `
			<p>אתה יכול להוריד את גרסת שולחן העבודה של <b>${description}</b> כאן.</p>
			<p>המספר הסידורי שלך מעניק רישיון אחד להתקנה על מחשב יחיד.</p>
		`,
	},
	instructions: {
		en: `
			<p>Please follow these simple steps:</p>
			<ol>
				<li>Download the Application: choose the right App-Version for your computer.</li>
				<li>Start the Installation: double-click the “Setup-Telestia-xxx” file
					(depending on your Operating System and Security Settings, you may get a system message asking you to confirm or abort the installation: please confirm).
					<ul>
						<li>In Windows:<br>Please follow on-screen instructions.</li>
						<li>In macOS:<br>Drag &amp; drop the App icon in the Applications folder.</li>
					</ul>
				</li>
			</ol>
			<p>If this is the first time you install the application, the following steps will also be performed:</p>
			<ol>
				<li>Start the Application.</li>
				<li>Activate your application: enter your "Serial Number" (provided in the box above).</li>
				<li>Log-in with your username and password.</li>
				<li>The application will download the course content files (videos, pictures, etc).<br>
					This will take several minutes, please be patient: this will only happen the first time you run the application or when new content is available.</li>
			</ol>
		`,
		el: `
			<p>Ακολουθήστε αυτά τα απλά βήματα:</p>
			<ol>
				<li>Κατεβάστε την εφαρμογή: επιλέξτε τη σωστή έκδοση εφαρμογής για τον υπολογιστή σας.</li>
				<li>Ξεκινήστε την εγκατάσταση: κάντε διπλό κλικ στο ληφθέν αρχείο
					(ανάλογα με το λειτουργικό σας σύστημα και τις ρυθμίσεις ασφαλείας, ένα μήνυμα συστήματος μπορεί να σας ζητήσει να επιβεβαιώσετε ή να ματαιώσετε την εγκατάσταση: επιβεβαιώστε).
					<ul>
						<li>Windows:<br>Παρακαλούμε ακολούθησε τις οδηγίες στην οθόνη.</li>
						<li>macOS:<br>Σύρτε την Εφαρμογή Telestia στον Φάκελο Εφαρμογές.</li>
					</ul>
				</li>
			</ol>
			<p>Εάν αυτή είναι η πρώτη φορά που εγκαθιστάτε την εφαρμογή, θα εκτελεστούν επίσης τα ακόλουθα βήματα:</p>
			<ol>
				<li>Ξεκινήστε την Εφαρμογή Telestia.</li>
				<li>Ενεργοποιήστε την Εφαρμογή σας: εισαγάγετε τον "Σειριακό Αριθμό" σας (δείτε παραπάνω).</li>
				<li>Συνδεθείτε με το όνομα χρήστη και τον κωδικό πρόσβασής σας.</li>
				<li>Η εφαρμογή θα κατεβάσει τα αρχεία περιεχομένου του μαθήματος (βίντεο, εικόνες, κ.λπ.).<br>
					Αυτό θα διαρκέσει αρκετά λεπτά, κάντε υπομονή: αυτό θα συμβεί μόνο την πρώτη φορά που θα εκτελέσετε την εφαρμογή ή όταν είναι διαθέσιμο νέο περιεχόμενο.</li>
			</ol>
		`,
		de: `
			<p>Bitte diese einfache Schritte folgen:</p>
			<ol>
				<li>Die Software herunterladen: bitte die entsprechende App-Version auswählen.</li>
				<li>Die Installation starten: die heruntergeladene Datei doppelanklicken
					(Je nach Betriebssystem und Sicherheitseinstellungen kann eine Systemmeldung erscheinen, ob die Installation ausgeführt werden darf oder abgebrochen werden soll: Bitte die Ausführung bestätigen).
					<ul>
						<li>Auf Windows:<br>Die Anleitung bitte folgen.</li>
						<li>Auf macOS:<br>Per Drag &amp; Drop die Telestia-App in den Anwendungsordner ziehen.</li>
					</ul>
				</li>
			</ol>
			<p>Wenn die Anwendung zum ersten Mal installiert wird, müsen außerdem die folgenden Schritte ausgeführt werden:</p>
			<ol>
				<li>Die App (Anwendung) starten.</li>
				<li>Die Anwendung aktivieren: "Seriennummer" bitte eingeben (siehe oben).</li>
				<li>Mit Benutzernamen und Passwort anmelden.</li>
				<li>Die Anwendung lädt deine Kursinhaltsdateien (Videos, Bilder usw.) herunter.<br>
					Dies dauert einige Minuten, bitte etwas Geduld haben: Dies wird bei der Erstausführung durchgeführt und immer wieder, wenn neue Inhalte verfügbar sind.</li>
			</ol>
		`,
		fr: `
			<p>Veuillez suivre ces étapes simples :</p>
			<ol>
				<li>Téléchargez l'application : choisissez la bonne version de l'application pour votre ordinateur.</li>
				<li>Démarrez l'installation : double-cliquez sur le fichier « Setup-Telestia-xxx ».
					(selon votre système d'exploitation et vos paramètres de sécurité, un message système peut vous demander de confirmer ou d'abandonner l'installation : veuillez confirmer).
					<ul>
						<li>Sous Windows :<br>Veuillez suivre les instructions à l'écran.</li>
						<li>Sous macOS :<br>Faites glisser et déposez l'application dans le dossier Applications.</li>
					</ul>
				</li>
			</ol>
			<p>Si c'est la première fois que vous installez l'application, les étapes suivantes seront également effectuées :</p>
			<ol>
				<li>Démarrez l'application :</li>
				<li>Activez votre Application : saisissez votre «numéro série» (voir ci-dessus).</li>
				<li>Connectez-vous avec votre nom d'utilisateur et votre mot de passe.</li>
				<li>L'application téléchargera les fichiers de contenu de votre cours (vidéos, images, etc).<br>
					Cela prendra plusieurs minutes, soyez patient : cela ne se produira que la première fois que vous lancerez l'application ou lorsqu'un nouveau contenu sera disponible.</li>
			</ol>
		`,
		he: `
			<p>אנא בצע את השלבים הפשוטים הבאים:</p>
			<ol>
				<li>הורד את האפליקציה: בחר את גרסת האפליקציה המתאימה למחשב שלך</li>
				<li>התחל את ההתקנה: לחץ פעמיים על הקובץ "Setup-Telestia-xxx"
					(בהתאם להגדרות מערכת ההפעלה והגדרות האבטחה שלך, הודעת מערכת עשויה לבקש ממך לאשר או לבטל את ההתקנה: נא אשר)
					<ul>
						<li>ב-Windows:<br>אנא עקוב אחר ההוראות שעל המסך</li>
						<li>ב-macOS:<br>גרור ושחרר את סמל האפליקציה בתיקיית היישומים</li>
					</ul>
				</li>
			</ol>
			<p>אם זו הפעם הראשונה שאתה מתקין את האפליקציה, יבוצעו גם השלבים הבאים:</p>
			<ol>
				<li>הפעל את האפליקציה</li>
				<li>הפעל את האפליקציה שלך: הזן את "המספר הסידורי" שלך (מסופק בתיבה למעלה)</li>
				<li>התחבר עם שם המשתמש והסיסמה שלך</li>
				<li>האפליקציה תוריד את קבצי תוכן הקורס (סרטונים, תמונות וכו')<br>
					זה ייקח מספר דקות, אנא התאזר בסבלנות: זה יקרה רק בפעם הראשונה שתפעיל את האפליקציה או כאשר תוכן חדש זמין</li>
			</ol>
		`,
	},
	note: {
		en: `
			<p>As we update the Software you will always find the newest Version to download on this page, so make sure to check here from time to time if your version is up to date, or if there is a new update available.</p>
			<p>Your new Desktop Version of the application has been designed to have the same look and feel as the Online Version. There is also seamless interoperability between the two. This means that the course progress and assignments are updated and synchronised on both platforms as you move on with your studies.</p>
			<p>You can work on your Desktop Version with or without internet connection. You may be prompted, however, to connect to the internet, in order to synchronise your data with your online Version and inform your Tutor of your progress.</p>
		`,
		el: `
			<p>Καθώς ενημερώνουμε το Λογισμικό, θα βρίσκετε πάντα την πιο πρόσφατη Έκδοση σε αυτήν τη σελίδα, επομένως φροντίστε να ελέγχετε εδώ κατά καιρούς εάν η έκδοσή σας είναι ενημερωμένη ή εάν υπάρχει διαθέσιμη νέα εκδοχή.</p>
			<p>Η νέα σας Desktop έκδοση της εφαρμογής έχει σχεδιαστεί για να έχει την ίδια εμφάνιση και αίσθηση με την Online έκδοση. Υπάρχει επίσης απρόσκοπτη διαλειτουργικότητα μεταξύ των δύο. Αυτό σημαίνει ότι η πρόοδος του μαθήματος και οι εργασίες ενημερώνονται και συγχρονίζονται και στις δύο πλατφόρμες καθώς προχωράτε στις σπουδές σας.</p>
			<p>Μπορείτε να εργαστείτε στην Desktop έκδοση με ή χωρίς σύνδεση στο διαδίκτυο. Ενδέχεται, ωστόσο, να σας ζητηθεί να συνδεθείτε στο διαδίκτυο, προκειμένου να συγχρονίσετε τα δεδομένα σας με την Online έκδοση και να ενημερώσετε την σας για την πρόοδό σας.</p>
		`,
		de: `
			<p>Da die Software regelmäßig aktualisiert wird, wirst findest du auf dieser Seite immer die neueste Version zum Herunterladen finden können. Bitte überprüfe daher von Zeit zu Zeit, ob deine Version auf dem neuesten Stand ist oder ob ein neues Update für dich verfügbar ist.</p>
			<p>Deine neue Desktop-Version ist mit der gleichen Benutzeroberfläche wie die Online-Version gestaltet. Es besteht außerdem eine nahtlose Interoperabilität zwischen den beiden. Das bedeutet, dass dein Kursfortschritt sowie deine Aufgaben im weiteren Verlauf deines Kurses auf beiden Plattformen aktualisiert und synchronisiert werden.</p>
			<p>Du kannst an deiner Desktop-Version mit oder ohne Internetverbindung arbeiten. Von Zeit zur Zeit wirst du jedoch aufgefordert, dich mit dem Internet zu verbinden, um deine Daten mit der Online-Version zu synchronisieren und deine Tutorin über deinen Fortschritt zu informieren.</p>
		`,
		fr: `
			<p>Au fur et à mesure que nous mettons à jour le logiciel, vous trouverez toujours la version la plus récente à télécharger sur cette page, alors assurez-vous de vérifier ici de temps en temps si votre version est à jour ou si une nouvelle mise à jour est disponible.</p>
			<p>Votre nouvelle version de bureau de l'application a été conçue pour avoir la même apparence que la version en ligne. Il existe également une interopérabilité transparente entre les deux. Cela signifie que la progression du cours et les devoirs sont mis à jour et synchronisés sur les deux plates-formes au fur et à mesure que vous avancez dans vos études.</p>
			<p>Vous pouvez travailler sur votre version de bureau avec ou sans connexion Internet. Vous pouvez toutefois être invité à vous connecter à Internet, afin de synchroniser vos données avec votre Version en ligne et informer votre Tuteur de votre progression.</p>
		`,
		he: `
			<p>ככל שאנו מעדכנים את התוכנה, תמיד תמצא את הגרסה החדשה ביותר להורדה בעמוד זה, אז הקפד לבדוק כאן מעת לעת אם הגרסה שלך מעודכנת, או אם יש עדכון חדש זמין.</p>
			<p>גרסת שולחן העבודה החדשה שלך של האפליקציה תוכננה כך שתהיה אותה מראה ותחושה כמו הגרסה המקוונת. יש גם יכולת פעולה הדדית חלקה בין השניים. המשמעות היא שהתקדמות הקורס והמטלות מתעדכנות ומסונכרנות בשתי הפלטפורמות תוך כדי המשך הלימודים.</p>
			<p>אתה יכול לעבוד על גרסת שולחן העבודה שלך עם או בלי חיבור לאינטרנט. עם זאת, ייתכן שתתבקש להתחבר לאינטרנט, כדי לסנכרן את הנתונים שלך עם הגרסה המקוונת שלך וליידע את המורה שלך על ההתקדמות שלך.</p>
		`,
	},
};

export default downloadInstructions;

