<template>
	<div id="downoads-page">
		<div class="page-header downloads-header row w-100 align-items-end mx-0 mb-4">
			<div class="title col-12 col-lg-8 mx-auto pb-2">
				<h3 class="text-white">{{ $t("telestia.title.downloads") }}</h3>
			</div>
		</div>

		<div class="row w-100 align-items-end mx-0 mb-4">
			<div class="col-12 col-lg-8 mx-auto pb-2">

				<div class="row w-100 align-items-end mx-0 mb-4">
					<div v-html="downloadInstructions.page[selectedLanguage] || downloadInstructions.page.en"></div>
				</div>

				<div class="row w-100 align-items-end mx-0 mb-4">
					<p v-if="!isOnlineBuild">
						{{ $t('telestia.downloads.current_version') }}
						<span class="version-badge" :class="versionCompare>=0 ? 'new' : 'old'">{{ version }}</span>
						<span v-if="versionCompare>=0">{{ $t('telestia.downloads.uptodate') }}</span>
						<span v-else>{{ $t('telestia.downloads.need_update') }}</span>
					</p>
					<p v-if="latestVersion!==''">
						{{ $t('telestia.downloads.latest_version') }}
						<span class="version-badge" :class="{ 'new': versionCompare<0 }">{{ latestVersion }}</span>
					</p>
					<p v-if="serialNumber!==''">
						{{ $t('telestia.downloads.serial_number') }}
						<input type="text" class="mx-3 selectable" size="40" :value="serialNumber" readonly onclick="this.select()"/>
					</p>
				</div>

				<div class="row w-100 align-items-end mx-0 mb-4">
					<div class="download col-3 mx-auto pb-2 text-center" :class="{default: defaultDownload=='win64'}">
						Windows 64-bit<br>
						<b-button class="px-2 py-1" variant="primary" :href="downloadLinks.win64" @click="checkDownload" target="iframeDownload">
							<font-awesome-icon size="lg" :icon="['fab','windows']"/><br>
							{{ $t('telestia.downloads.download') }}
						</b-button>
					</div>
					<div class="download col-3 mx-auto pb-2 text-center" :class="{default: defaultDownload=='win32'}">
						Windows 32-bit<br>
						<b-button class="px-2 py-1" variant="primary" :href="downloadLinks.win32" @click="checkDownload" target="iframeDownload">
							<font-awesome-icon size="lg" :icon="['fab','windows']"/><br>
							{{ $t('telestia.downloads.download') }}
						</b-button>
					</div>
					<div class="download col-3 mx-auto pb-2 text-center" :class="{default: defaultDownload=='macos'}">
						macOS<br>
						<b-button class="px-2 py-1" variant="primary" :href="downloadLinks.macos" @click="checkDownload" target="iframeDownload">
							<font-awesome-icon size="lg" :icon="['fab','apple']"/><br>
							{{ $t('telestia.downloads.download') }}
						</b-button>
					</div>
				</div>

			</div>
		</div>

		<hr>

		<div class="row w-100 align-items-end mx-0 mb-4">
			<div class="col-12 col-lg-8 mx-auto pb-2">

				<div class="row w-100 align-items-end mx-0 mb-4">
					<h3>{{ $t('telestia.title.installinstructions') }}</h3>

					<div v-html="downloadInstructions.instructions[selectedLanguage] || downloadInstructions.instructions.en"></div>
				</div>

			</div>
		</div>

		<hr>

		<div class="row w-100 align-items-end mx-0 mb-4">
			<div class="col-12 col-lg-8 mx-auto pb-2">

				<div class="row w-100 align-items-end mx-0 mb-4">
					<h5>{{ $t('telestia.title.note') }}</h5>

					<div v-html="downloadInstructions.note[selectedLanguage] || downloadInstructions.note.en"></div>
				</div>

			</div>
		</div>

		<iframe id="iframeDownload" name="iframeDownload" style="display:none;visibility:hidden"></iframe>
	</div>
</template>

<script>
import envConfig from '../../shared/configs/config.local.js'
import { version } from '../../../package.json';
const semverCompare = require('semver/functions/compare');
import downloadInstructions from './downloadInstructions.js';
import axios from 'axios';
import md5 from 'md5';

export default {
	name: 'downloads',

	created() {
		//check if user is allowed to download the app
		if (!this.$store.getters['user/canDownloadApp']) {
			this.$router.push({ name: 'App:Profile' });
	 	}
	},

	async mounted() {
		this.defaultDownload = (()=>{
			const userAgent = window.navigator.userAgent.toLowerCase();
			if (userAgent.match('mac')) {
				return 'macos';
			} else if (userAgent.match('(win64|wow64)')) {
				return 'win64';
			} else if (userAgent.match('win')) {
				return 'win32';
			}
		})();
		await this.getLatestVersion();
		await this.getSerialNumber();
		this.generateDownloadLinks();
	},

	data() {
		return {
			version: version,
			latestVersion: '',
			versionCompare: 0,
			serialNumber: '',
			downloadInstructions: downloadInstructions,
			downloadLinks: {
				win64: '',
				win32: '',
				macos: '',
			},
			defaultDownload: '', 
		};
	},

	computed: {
		isOnlineBuild() { return this.$store.getters['app/isOnline'] },

		selectedLanguage() { return this.$store.getters['user/getSelectedLanguage'] },
	},

	methods: {

		setLatestVersion(latestVersion){
			this.latestVersion = latestVersion;
			this.versionCompare = this.isOnlineBuild ? 0 : semverCompare(version,this.latestVersion);
		},

		async getLatestVersion(){
			const lastCheck = JSON.parse(localStorage.getItem('lastVersionCheck') || '{}');
			//check version at most every 15 minutes
			if (lastCheck.version && ((lastCheck.when||0)+(15*60*1000) > Date.now())) {
				this.setLatestVersion(lastCheck.version);
				return true;
			}
			return await axios.get(`${envConfig.FILE_SERVER}/version`).then((response) => {
				if (response.data !== null && response.data.version) {
					this.setLatestVersion(response.data.version);
					localStorage.setItem('lastVersionCheck', JSON.stringify({
						when: Date.now(),
						version: this.latestVersion,
					}));
					return true;
				} else {
					console.log('ERROR retrieving version: unexpected data.');
					if (lastCheck.version) this.setLatestVersion(lastCheck.version);
					return false;
				}
			}).catch(error => {
				console.log(error,lastCheck);
				if (lastCheck.version) this.setLatestVersion(lastCheck.version);
				return false;
			});
		},

		async getSerialNumber(){
			let sn = this.$store.getters['user/getSerialNumber'];
			if (sn) {
				this.serialNumber = sn;
				return;
			}
			sn = await this.$store.dispatch('user/fetchSerialNumber');
			if (sn) {
				this.serialNumber = sn;
				this.$store.commit('user/serialNumberSave', sn);
			}
		},

		generateDownloadLinks() {
			if (!this.serialNumber || !this.latestVersion) return;

			const BASEURL = 'https://www.fashionsoftwareonline.com/versions/';
			const SECRET = '46defr7tghuyjikolp;7r89';
			const fnw64 = `win/tls/Setup-Telestia-x64-${this.latestVersion}.exe`;
			const fnw32 = `win/tls/Setup-Telestia-ia32-${this.latestVersion}.exe`;
			const fnmac = `mac/tls/Setup-Telestia-${this.latestVersion}.dmg`;
			this.downloadLinks = {
				win64: `${BASEURL}?b=1&v=${fnw64}&l=${this.serialNumber}&k=` + md5(fnw64+this.serialNumber+SECRET),
				win32: `${BASEURL}?b=1&v=${fnw32}&l=${this.serialNumber}&k=` + md5(fnw32+this.serialNumber+SECRET),
				macos: `${BASEURL}?b=1&v=${fnmac}&l=${this.serialNumber}&k=` + md5(fnmac+this.serialNumber+SECRET),
			};
		},

		checkDownload(e) {
			if (e.target.href) return true;
			this.$swal({ title: this.$t('telestia.title.error'), text: this.$t('telestia.error.downloads'), icon: 'error', timer: 6000, button: true });
			e.preventDefault();
			return false;
		},

	},

}
</script>

<style scoped>
.downloads-header {
	background-image: url(../../assets/images/page-header/downloads.jpg);
}

ol {
	padding-left: revert;
}

.version-badge {
	background: lightgray;
	padding: 2px 6px;
	border-radius: 5px;
	margin: 7px;
}
.version-badge.new {
	background: green;
	color: white;
}
.version-badge.old {
	background: red;
	color: white;
}

div.download.default {
	border: 3px solid green;
	border-radius: 10px;
}
</style>

